<template>
    <div class="row">
        <div class="col-12">
            <b-overlay :show="register_show_overlay" rounded="sm">
                <b-modal
                    id="modal-create"
                    cancel-variant="secondary"
                    ok-title="Register"
                    cancel-title="Cancel"
                    centered
                    title="Register User"
                    @ok="userRegister"
                >
                    <b-form>
                        <b-form-group label="Email">
                            <b-input-group>
                                <b-input-group-prepend is-text>
                                    <feather-icon icon="MailIcon" />
                                </b-input-group-prepend>
                                <b-form-input v-model="newUserEmail" type="email" placeholder="Email" v-bind:[emailState]="emailValidation" />
                            </b-input-group>
                            <b-form-invalid-feedback v-bind:[emailState]="emailValidation"> email format error </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group label="Password">
                            <b-input-group>
                                <b-input-group-prepend is-text>
                                    <feather-icon icon="LockIcon" />
                                </b-input-group-prepend>
                                <b-form-input v-model="newUserPassword" placeholder="must contain number and letter length 6-20" v-bind:[emailState]="passwordValidation" />
                            </b-input-group>
                            <b-form-invalid-feedback v-bind:[passwordState]="passwordValidation">
                            must contain number and letter,length 6-20.
                        </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group label="Roles">
                            <b-form-checkbox-group v-model="newUserRoles" :options="rolesOption" switches class="demo-inline-spacing" />
                        </b-form-group>
                    </b-form>
                </b-modal>
            </b-overlay>
        </div>

        <div class="col-12">
            <b-card header="Manager User">
                <div class="pl-3 pr-3 pb-3">
                    <b-row class="mb-3">
                        <b-col>
                            <b-button class="mr-2" variant="primary" v-b-modal.modal-create> Add User </b-button>

                            <VDropdown>
                                <b-button variant="secondary" class="mr-3 ml-2">
                                    <feather-icon icon="SearchIcon" class="mr-2" size="15" />
                                    <span class="align-middle">Search-items</span>
                                </b-button>
                                <!-- This will be the content of the popover -->
                                <template #popper>
                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <b-form-input placeholder="email pattern" v-model="queryEmailPattern" />
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <b-form-input placeholder="user id" v-model="queryUserId" />
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-1 mt-3">
                                        <b-col>
                                            <b-button variant="secondary" v-close-popper="true" @click="search"> Search</b-button>
                                            <b-button class="ml-2" variant="secondary" @click="clearQueryCondition"> Clear</b-button>
                                        </b-col>
                                    </b-row>
                                </template>
                            </VDropdown>

                        </b-col>
                    </b-row>

                    <b-overlay :show="table_show_overlay" rounded="sm">
                        <vue-good-table
                            ref="remote_q_table"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRows"
                            :columns="columns"
                            :rows="row_data"
                            :sort-options="{ enabled: false }"
                            :pagination-options="pagination"
                        >
                            <template slot="table-row" slot-scope="props">
                                <!-- Column: Common -->
                                <!-- <span>{{ props.row[props.column.field] }}</span> -->

                                <span v-if="props.column.field === 'roles'">
                                    <b-badge v-for="role in props.row[props.column.field]" variant="primary" class="ml-1 mr-1" :key="role">
                                        {{ role }}</b-badge
                                    >
                                </span>

                                <!-- <span v-else-if="props.column.field === 'permissions'">
                                <b-badge v-for="permission in props.row[props.column.field]" variant="primary" class="ml-1 mr-1" :key="permission">
                                    {{ permission }}</b-badge>
                            </span> -->

                                <span v-else-if="props.column.field === 'forbidden'">
                                    <b-badge v-if="!props.row['forbidden']" pill variant="success" class="mr-3">active</b-badge>
                                    <b-badge v-else pill class="mr-3">forbidden</b-badge>
                                </span>

                                <span v-else-if="props.column.field === 'action1' && $store.state.auth.my_roles.includes('admin')">
                                    <!-- <b-button variant="danger" @click="deleteUser(props.row)">Delete</b-button> -->
                                    <b-button variant="primary" @click="open_row_view_edit(props.row.originalIndex)">Edit/Delete</b-button>
                                </span>
                            </template>
                        </vue-good-table>
                    </b-overlay>

                    <!-- modal_row_view_update-->
                    <b-modal
                        id="modal_row_view_update"
                        size="lg"
                        @hide="handleHide"
                        title="view/edit"
                        cancel-variant="secondary"
                        cancel-title="Close"
                        centered
                    >
                        <b-overlay :show="modal_row_view_overlay" rounded="sm">
                            <b-form>
                                <b-form-group>
                                    <label>Email:</label>
                                    <b-form-input id="name" type="text" v-model="focusName" disabled />
                                </b-form-group>

                                <b-form-group>
                                    <label>Roles:</label>
                                    <b-form-checkbox-group v-model="updateUserRoles" :options="rolesOption" switches class="demo-inline-spacing" />
                                </b-form-group>

                                <b-form-group>
                                    <label>Active:</label>
                                    <b-form-checkbox v-model="updateUserStatus" name="check-button" class="custom-control-success" switch>
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-form>
                        </b-overlay>

                        <template #modal-footer>
                            <b-row class="w-100">
                                <b-col class="text-left col p-0">
                                    <b-button variant="danger" @click="deleteUser">Delete</b-button>
                                </b-col>
                                <b-col cols="8" class="text-right col-8 p-0">
                                    <b-button variant="primary" class="mr-3" @click="updateUser">Update</b-button>
                                    <b-button variant="secondary" @click="$bvModal.hide('modal_row_view_update')">Cancel</b-button>
                                </b-col>
                            </b-row>
                        </template>
                    </b-modal>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInvalidFeedback,
    BAlert,
    BForm,
    BFormCheckbox,
    BFormCheckboxGroup,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

export default {
    components: {
        BCard,
        BCardFooter,
        BRow,
        BCol,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BButton,
        BOverlay,
        BBadge,
        BFormGroup,
        BFormInvalidFeedback,
        BAlert,
        BForm,
        BFormCheckbox,
        BFormCheckboxGroup,
        VueGoodTable,
    },
    computed: {
        emailState() {
            return this.newUserEmail.length != 0 ? "state" : null;
        },
        emailValidation() {
            return this.$tools.validator.validateEmail(this.newUserEmail)
        },
         passwordState() {
            return this.newUserEmail.length != 0 ? "state" : null;
        },
        passwordValidation() {
            if (this.newUserPassword === "") {
                return true;
            }
            return this.$tools.validator.validatePassword(this.newUserPassword)
        },
    },

    methods: {
        clearQueryCondition() {
            this.queryEmailPattern = "";
            this.queryUserId = "";
        },
        onPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },

        async search() {
            this.$refs['remote_q_table'].reset();
            this.offset = 0;
            await this.refreshTable();
        },

        async getAuthConfig(){
            let resp = await this.$api.user.getAuthConfig();
            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.rolesOption=resp.result.roles
        },
        async refreshTable() {
            this.table_show_overlay = true;
            let resp = await this.$api.user.queryUser(
                this.queryEmailPattern,
                parseInt(this.queryUserId),
                this.limit,
                this.offset,
                this.$store.state.auth.my_web_token
            );
            this.table_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.row_data = resp.result.user_list;
            this.totalRows = resp.result.count;
        },

        open_row_view_edit(rowid) {
            this.focus = this.row_data[rowid];
            this.focusName = this.focus.email;
            this.updateUserRoles = this.focus.roles;
            this.updateUserStatus = !this.focus.forbidden;
            this.$bvModal.show("modal_row_view_update");
        },
        handleHide(bvModalEvt) {
            if (this.remote_updating) {
                bvModalEvt.preventDefault();
            }
        },

        async updateUser() {
            let resp = await this.$api.user.updateUser(
                this.focus.id,
                !this.updateUserStatus,
                this.updateUserRoles,
                null,
                this.$store.state.auth.my_web_token
            );

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("user updated", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });
            this.refreshTable();
            this.$bvModal.hide("modal_row_view_update");
        },

        async deleteUser() {
            let email = this.focus.email;
            let id = this.focus.id;
            let value = await this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete User [${email}]`, {
                title: "Delete User",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "No",
                cancelVariant: "secondary",
                hideHeaderClose: false,
                centered: true,
            });
            if (value) {
                let resp = await this.$api.user.deleteUser(id, this.$store.state.auth.my_web_token);

                if (resp.err !== null) {
                    this.$bvToast.toast(resp.err, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                if (resp.result.meta_status < 0) {
                    this.$bvToast.toast(resp.result.meta_message, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                this.$bvToast.toast("user deleted", {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                this.refreshTable();
                this.$bvModal.hide("modal_row_view_update");
            }
        },

        async userRegister(bvModalEvt) {
            if (this.emailValidation == false) {
                bvModalEvt.preventDefault();
                this.$bvToast.toast("email format error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (this.passwordValidation == false) {
                bvModalEvt.preventDefault();
                this.$bvToast.toast("password format error", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (this.newUserRoles.length == 0) {
                bvModalEvt.preventDefault();
                this.$bvToast.toast("user needs at least one role", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.register_show_overlay = true;
            let resp = await this.$api.user.addUser(
                this.newUserEmail,
                this.newUserPassword,
                this.newUserRoles,
                [],
                this.$store.state.auth.my_web_token
            );
            this.register_show_overlay = false;
            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("user added", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });
            this.refreshTable();
        },
    },
    mounted() {
        this.refreshTable();
        this.getAuthConfig();
    },
    data() {
        return {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Email",
                    field: "email",
                    type: "text",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Roles",
                    field: "roles",
                    type: "text",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                // {
                //     label: "Permissions",
                //     field: "permissions",
                //     type: "text",
                //     tdClass: "text-center",
                //     thClass: "text-center",
                // },
                {
                    label: "Active",
                    field: "forbidden",
                    type: "text",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Token",
                    field: "token",
                    type: "text",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Action",
                    field: "action1",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
            ],
            pagination: {
                enabled: true,
                mode: "pages",
                perPage: 10,
                perPageDropdown: [5, 10, 20, 50, 100, 250, 500, 1000],
                setCurrentPage: 1,
            },
            row_data: [],
            limit: 10,
            offset: 0,
            totalRows: 0,

            table_show_overlay: true,
            queryEmailPattern: "",
            queryUserId: "",
            newUserEmail: "",
            newUserPassword: "",
            newUserRoles: [],
            rolesOption: [],
            register_show_overlay: false,

            modal_row_view_overlay: false,
            focus: {},
            focusName: "",
            updateUserRoles: [],
            updateUserStatus: false,
        };
    },
};
</script>
